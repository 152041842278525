import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react'
import {
	useScroll,
	motion,
	useTransform,
	useInView,
} from 'framer-motion/dist/framer-motion'
import { PortableText } from './PortableText'
import { useWindowSize } from 'react-use'
import SanityImage from './SanityImage'
import { useImageSize } from '../hooks/useImageSize'
import cx from 'clsx'
import { useScrollContext } from '../context/ScrollContext'
import { getListSegments, getCardIndex } from "../utils"

const Topics = ({ cards }) => {
	const [indicatorRange, setIndicatorRange] = useState([0, 0.25])
	const cardsObj = {}
	const { updateState } = useScrollContext()
	const { height, width } = useWindowSize()
	const scrollRef = useRef(null)
	const viewRef = useRef(null)
	const topRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.25, once: true })
	const [currentIndex, setCurrentIndex] = useState(0)
	const [scrolling, setScrolling] = useState(false)
	const [current, setCurrent] = useState(cards[currentIndex]._key)
	const [currentTitle, setCurrentTitle] = useState(cards[currentIndex]._key)
	const [currentLink, setCurrentLink] = useState(cards[currentIndex]._key)
	const [scrollSubHeight, setScrollSubHeight] = useState(`${(1 / cards.length) * 100}%`)
	const allRefs = useRef({})
	const [previous, setPrevious] = useState(null)
	const segments = getListSegments(cards)
	const imageSize = useImageSize(0.3270)

	cards.forEach(card => {
		cardsObj[card._key] = card
	})

	const handleCurrent = useCallback((key) => {
		if (key !== current) setPrevious(current)
		setCurrentLink(key)
		setCurrentTitle(key)
		setTimeout(() => {
			setCurrent(key)
		}, 450)
	}, [current])

	const { scrollYProgress } = useScroll({
		target: scrollRef,
		offset: ['start end', 'end end']
	})

	const handleScroll = (_key) => {
		allRefs[_key].scrollIntoView({ behavior: 'smooth', block: 'end' })
	}

	useLayoutEffect(() => {
		setScrollSubHeight(`${(1 / cards?.length) * 100}%`)
	}, [cards.length])

	useEffect(() => {
		return scrollYProgress.onChange((latest) => {
			latest > 0 && latest < 1 ? updateState(true) : updateState(false)
			setScrolling(latest > 0)
		})
	}, [scrollYProgress, updateState])

	useLayoutEffect(() => {
		return scrollYProgress.onChange((latest) => {
			handleCurrent(cards[currentIndex]._key)
			setCurrentIndex(getCardIndex(latest, segments, setIndicatorRange))
		})
	}, [scrollYProgress, segments, currentIndex, cards, handleCurrent, height, width])

	const yProgress = useTransform(
		scrollYProgress,
		indicatorRange,
		[0, 1],
	)

	const mobileYProgress = useTransform(
		scrollYProgress,
		[0, 1],
		[0, 1],
	)

	return (
		<section
			id='topics'
			className='z-50'
			ref={topRef}
		>
			<div
				className={cx(
					'sticky top-0 h-fit md:pt-[1%] flex flex-col md:flex-row items-center',
					inView ? 'slide-up' : 'translate-y-6 opacity-0'
				)}
				ref={viewRef}
			>
				<div className='block self-center my-auto min-w-[45%] pt-3 md:pt-0 md:min-w-[33%] order-2 md:order-1'>
					{cards?.map(({ _key }) =>
						<div
							key={_key}
							className={cx(
								'relative pb-[150%]',
								_key === current ? 'animate-fadeIn' : 'hidden',
								previous === _key ? 'animate-fadeOut' : ''
							)}
						>
							<SanityImage
								viewportFadeIn
								loading='eager'
								maxWidth={imageSize}
								image={cardsObj[current]._rawIllustration}
								className='absolute left-0 top-0 w-full h-full object-contain md:pl-[10%] md:py-[5%] md:pr-[5%]'
							/>
						</div>
					)}
				</div>
				<div className='md:w-full md:order-2'>
					<ul className={cx(
						'stagger hidden md:block bg-white overflow-hidden py-[5%] ml-auto w-11/12 md:py-1 md:w-2/3 md:ml-auto',
						inView && 'stagger-item'
					)}>
						{cards?.map(({ _key, title }, i) =>
							<li
								key={_key}
								className='relative'
								id={title}
							>
								<button
									tabIndex={0}
									onClick={() => handleScroll(_key)}
									onKeyDown={() => handleScroll(_key)}
								>
									<h3 className={cx(
										'h4-alt py-[1%] leading-normal cursor-pointer will-change-transform pl-1 pr-4 -rotate-3 overflow-visible',
										currentTitle === _key && scrolling ? 'text-alt' : 'fade-color-out',
									)}>
										{title}
									</h3>
									<div className={cx(
										'h-[3px] bg-black lg:h-1 absolute bottom-0 block w-full',
									)} />
									<motion.div
										className={cx(
											'h-[3px] bg-alt origin-left lg:h-1 z-10 w-full absolute bottom-0',
										)}
										style={{
											scaleX: currentLink === _key ? yProgress : 0,
										}}
									/>
								</button>
							</li>
						)}
					</ul>
					<div className='block py-[5%] md:hidden w-3/4 ml-auto relative overflow-visible'>
						{cards?.map(({ _key, title }, i) =>
							<h3
								key={_key}
								className={cx(
									'h4-alt pb-[2%] max-w-max leading-relaxed will-change-transform -rotate-3 px-4 overflow-visible',
									_key === currentTitle ? 'block' : 'hidden',
								)}>
								{title}
							</h3>
						)}
						<div className={cx(
							'h-[3px] bg-black absolute w-full block',
						)} />
						<motion.div
							className={cx(
								'h-[3px] bg-alt origin-left',
							)}
							style={{
								scaleX: mobileYProgress.current === 1 ? '1' : mobileYProgress
							}}
						/>
					</div>
					<div className='px-10 md:px-0 md:py-[4%] 2xl:py-[6%]'>
						{cards?.map(({ _key, title }) =>
							<div
								id={title}
								key={_key}
								className={cx(
									'md:h-[50vh] relative',
									_key === current ? 'block animate-fadeIn absolute' : 'hidden',
									previous === _key ? 'animate-fadeOut' : ''
								)}
							>
								<h4 className='h4--desktop text-left md:text-left md:h4 md:pb-[2%]'>{cardsObj[current].headline}</h4>
								<div className='md:min-h-0 md:pl-[4%] md:pr-[4%] lg:pr-[8%] xl:pr-[12%]'>
									<PortableText className='topics rich-text' blocks={cardsObj[current]._rawText} />
									{cardsObj[current].list[0] &&
										<ul className='md:flex body-xs justify-between 2xl:pt-[6%]'>
											{cardsObj[current].list.map(item =>
												<li className='not-last:pr-4'>
													<span className='font-bold pr-2'>{`\u2713`}</span>
													<span>{item}</span>
												</li>
											)}
										</ul>}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div
				className='relative h-[4000px] -z-10'
				ref={scrollRef}
			>
				{cards.map(({ title, _key }, i) =>
					<div
						key={i}
						className='w-full'
						style={{ height: scrollSubHeight }}
						id={title}
					>
						<div
							className='w-full h-44 md:h-10'
							ref={(ref) => allRefs[_key] = ref}
						/>
					</div>
				)}
			</div>
		</section>
	);
}
export default Topics;

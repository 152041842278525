import React, { useState, useRef } from 'react'
import { useInView } from 'framer-motion/dist/framer-motion'
import { PortableText } from './PortableText'
import cx from 'clsx'

const FAQ = ({ faq }) => {
	const [current, setCurrent] = useState(null)
	const [previous, setPrevious] = useState(null)
	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.25, once: true })

	const handleCurrent = (key) => {
		if (current === null) {
			setCurrent(key)
			return
		}
		if (key === current) {
			setPrevious(current)
			setTimeout(() => {
				setCurrent(null)
				setPrevious(null)
			}, 775)
			return
		}
		setPrevious(current)
		setTimeout(() => {
			setCurrent(key)
		}, 775)
	}

	return (
		<section
			id='faq'
			className='pt-10 md:header-padding'
			ref={viewRef}
		>
			<div className='flex flex-col items-center pb-[5%]'>
				<h2 className={cx(
					'h1 -mb-6 z-10 md:-mb-12 opacity-0',
					inView && 'animate-view'
				)}>
					FAQ
				</h2>
				<div className='text-center relative min-w-full py-10 md:py-20'>
					<div className='absolute inset-0 bg-primary w-4/5 absolute-center -z-10 h-full py-10 rounded-sm md:rounded-lg md:py-20' />
					<ul className={cx(
						'opacity-0',
						inView && 'animate-view'
					)}>
						<div className='border-b-2 absolute w-full inset-x-0' />
						{faq?.questions.map((item, i) =>
							<li
								key={i}
							>
								<button
									className='cursor-pointer px-[10%] py-5 md:pt-10 md:pb-9'
									aria-label='open FAQ'
									tabIndex='0'
									onClick={() => handleCurrent(i)}
									onKeyDown={() => handleCurrent(i)}
								>
									<h3 className='h5 cursor-pointer'>
										{item.question}
									</h3>
									<div className={cx(
										i === current ? 'animate-enter' : 'hidden',
										i === previous ? 'animate-exit' : ''
									)}>
										<PortableText className='faq rich-text' blocks={item._rawAnswer} />
									</div>
									<div className='border-b-2 inset-x-0 absolute w-full mt-4 md:mt-8' />
								</button>
							</li>
						)}
					</ul>
				</div>
			</div>
		</section>
	);
}

export default FAQ;
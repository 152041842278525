import React from "react"
import SanityImage from "./SanityImage"
import { useImageSize } from "../hooks/useImageSize"

const BottomImage = ({ image }) => {
	const imageSize = useImageSize(1)

	return (
		<SanityImage
			className='w-full'
			viewportFadeIn
			image={image}
			maxWidth={imageSize}
			loading='lazy'
		/>
	);
}

export default BottomImage;
import React, { useState, useEffect } from 'react'
import { splitTitle } from '../utils'
import { useWindowSize, useMedia } from 'react-use'
import SanityImage from './SanityImage'

const HeroHow = ({ image, title }) => {
	const { width } = useWindowSize()
	const isWide = useMedia('(min-width: 768px)')
	const [heroWidth, setHeroWidth] = useState(null)
	const headlineSplit = splitTitle(title)

	useEffect(() => {
		if (width) {
			const loadWidth = width * 0.93
			isWide ? setHeroWidth(Math.ceil(loadWidth)) : setHeroWidth(width)
		}
	}, [width, isWide])


	return (
		<section
			id='heroHow'
			className='md:pb-[5%] md:px-[3.5%]'>
			<div className='flex flex-col items-center relative md:max-h-screen'>
				<div className='w-full bg-transparent -z-10 header-padding'>
					<div
						className='min-h-[300px] aspect-w-16 aspect-h-8'
						width={{ heroWidth }}
					>
						<SanityImage
							loading='eager'
							className='object-cover pt-4 md:pt-0 md:hero-height md:w-full'
							image={image}
							maxWidth={heroWidth}
						/>
					</div>
					<div className='stagger-container block mx-auto py-5 -rotate-12 md:bottom-[5%] md:right-[2%] max-w-max md:absolute md:text-white'>
						<h1 className='how-headline'>
							<span className='block '>{headlineSplit?.top}</span>
							<span className='block ml-12'>{headlineSplit?.bottom}</span>
						</h1>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HeroHow;
import { graphql, useStaticQuery } from 'gatsby'

export const useHow = () => {
	const data = useStaticQuery(graphql`
	query {
		how: sanityHow {
			hero {
				title
				_rawImage
				image {
					...Image
					alt
				}
			}
			topic {
				cards {
					_key
					_rawText(resolveReferences: { maxDepth: 10 })
					headline
					_rawIllustration
					illustration {
						...Image
						alt
					}
					title
					list
				}
			}
			faq {
				questions {
					question
					_rawAnswer
				}
			}
			_rawBottomImage
			bottomImage {
				image {
					...Image
					alt
				}
			}
			seo {
				title
				description
				image {
					asset {
						url
					}
				}
			}
		}
	}
	`)

	// const { how } = data || {}
	return data || {}
}

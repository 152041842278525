import React from 'react'
import Meta from '../components/Meta'
import { useHow } from '../hooks/useHow'
import HeroHow from '../components/HeroHow'
import FAQ from '../components/FAQ'
import BottomImage from '../components/BottomImage'
import Topics from '../components/Topics'

const How = () => {
	const { how } = useHow()
	if (!how) return null
	const { seo, hero, topic, faq, _rawBottomImage } = how

	return (
		<>
			<Meta pageTitle="How" props={seo} />
			<div className="">
				<HeroHow image={hero?._rawImage} title={hero?.title} />
				<Topics {...topic} />
				<FAQ faq={faq} />
			</div>
			{_rawBottomImage &&
				<BottomImage {..._rawBottomImage} />
			}
		</>
	)
}

export default How
